.container {
  display: flex;
  flex-direction: column;
  background: rgb(250, 250, 250);
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 8px;
}

.container h2 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 1em;
}

.conteudo {
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-sizing: border-box;
}

.filtro {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.paginacao {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.conteudo .itens {
  display: flex;
  justify-content: flex-end;
  padding: 0 1em 1em;
}