.container {
  display: flex;
  flex-direction: column;
  height: 130px;
  background-color: var(--cor-cinza-escuro);
  color: white;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5px;
  box-shadow: 0 1px 2px #0003;
  gap: 1em;
}

.pipe {
  border-top: 1px solid white;
  width: 100%;
}

.botoes {
  display: flex;
  gap: .5em
}
