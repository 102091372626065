.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  border: 1px solid var(--cor-cinza);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 8px;
  outline: none;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5)
}

.formulario {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.formulario input:not(:last-child){
  margin-bottom: 1em;
}

.header h2 {
  color: var(--cor-primaria);
  font-size: 18px;
  padding: 2em;
  background-color: #f5f5fa;
}

.body {
  display: flex;
  padding: 2em;
  flex-wrap: wrap;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: space-around;
  padding: 0em 2em 2em 2em;
}