.card {
  display: flex;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1em;
  align-items: center;
}

.card h3 {
  font-size: 18px;
  font-weight: 600;
}

.conteudo {
  display: flex;
  flex: 1;
  margin-left: 2em;
  justify-content: space-between;
  align-items: center;
}

.periodo {
  display: flex;
  align-items: center;
  gap: 1em;
}
