.botao {
  padding: 1em 2em;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  width: fit-content;
}

.botao-primaria {
  background: var(--cor-primaria-gradiente-vertical);
}

.botao-primaria:hover {
  background: var(--cor-primaria-escura);
}

.botao-vermelho {
  background-color: var(--cor-vermelho);
}

.botao-vermelho:hover {
  background-color: var(--cor-vermelho-escuro);
}
