.container {
  background: white;
  padding: 1em;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 8px;
}

.container td,
.container th,
.container table,
.container tr {
  display: flex;
}

.container table {
  flex-direction: column;
}

.container tr {
  flex: 1;
  justify-content: space-around;
}

.container tr:hover {
  background-color: rgb(183, 224, 255);
  cursor: pointer;
}

.container th {
  font-weight: 600;
  background-color: rgb(216, 238, 255);
  user-select: none;
}

.container th,
.container td {
  padding: .5em;
  font-size: 13px;
}

.espera {
  width: 50px;
}

.atendimento {
  width: 85px;
}

.resposta {
  width: 60px;
}

.cliente,
.atendente {
  flex: 1;
}

.protocolo {
  width: 140px;
}

.tabela_conteudo .espera,
.tabela_conteudo .atendimento,
.tabela_conteudo .resposta {
  text-align: center;
  justify-content: center;
}
