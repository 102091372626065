.container {
  background: white;
  padding: 1em;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 8px;
}

.container h3 {
  font-size: 20px;
}

.filtro,
.inputs,
.inputGrupo,
.corpo {
  display: flex;
}

.filtro {
  padding: 1em;
  gap: .5em;
}

.periodo {
  width: 250px;

}

.periodo>* {
  height: 36px;
}

.inputs>* {
  display: flex;
  width: 120px;
  height: 36px;
}

.inputGrupo {
  flex: 1;
}

.inputGrupo>* {
  flex: 1;
}

.corpo {
  padding: 1em;
  justify-content: space-around;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: 1px solid var(--cor-cinza);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  height: fit-content;
  gap: .5em;
}