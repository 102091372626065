.filtro {
  display: flex;
  flex-direction: column;
  gap: .5em
}

.filtro li {
  display: flex;
  gap: .5em;
}

.itens {
  display: flex;
  width: 300px;
  align-items: center;
  gap: .3em;
}

.itens>* {
  width: 170px;
}

.periodo {
  display: flex;
  align-items: center;
  gap: .3em;
}

.span {
  width: 100px;
}

.input_protocolo {
  width: 148px;
  height: 10px;
  padding: .8em;
  border: 1px solid #ddd;
  border-radius: 6px;
}