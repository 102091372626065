.paginacao button {
  border-radius: 8px;
  padding: 0.5em;
  margin: 0 .5em;
}

.paginas,
.alteraPagina {
  background-color: white;
  border: 1px solid rgb(192, 192, 192);
}

.paginas:hover,
.alteraPagina:hover {
  background-color: rgb(240, 240, 240);
}

.paginas:hover,
.alteraPagina:active {
  background-color: rgb(199, 199, 199);
}

.paginaAtual {
  border: 1px solid rgb(134, 134, 134);
  background: var(--cor-primaria);
}