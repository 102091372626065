.formulario {
  display: flex;
  flex-wrap: wrap;
  width: 420px;
  background: #ffffff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.formulario form {
  display: flex;
  flex-wrap: wrap;
}

.cartao__header {
  display: flex;
  width: 100%;
  height: 70px;
  background: #f5f5fa;
  padding: 15px 25px;
  align-items: center;
  text-align: center;
}

.cartao__header img {
  height: 50px;
}

.cartao__header h2 {
  color: gray;
  font-weight: 700;
  font-size: 25px;
}

.cartao__body {
  width: 100%;
  align-items: center;
  padding: 2em;
}

.cartao__body input {
  width: 90%;
  padding: 1em;
  margin-bottom: 1em;
  border: 0;
  border-bottom: 1px solid #9596a2;
}

.cartao__footer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  padding: 0 2em 2em 2em;
}

.cartao__footer button {
  padding: 0.5em 2em;
  color: white;
  background-color: var(--cor-primaria);
  border: none;
  border-radius: 8px;
}

.cartao__footer p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
  cursor: pointer;
}
