.container {
  display: flex;
  flex-direction: column;
  padding: 1em
}

.titulo {
  font-size: 16px;
  font-weight: 600;
}

.conteudo {
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: .5em;
  width: 250px;
}