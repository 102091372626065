.card {
  display: flex;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1em;
}

.card h3 {
  font-size: 18px;
  font-weight: 600;
}

.conteudo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.conteudo input {
  display: flex;
  width: 150px;
}