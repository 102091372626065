.botao__chat {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  border: none;
  width: 70px;
  cursor: pointer;
  padding: 4px 3px;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.botao__chat:hover {
  background-color: rgb(231, 231, 231);
}

.botao__chat span {
  font-size: 12px;
  color: black;
}

.icone {
  font-size: 20px;
  padding: 2px;
  color: var(--cor-primaria-escura);
}
