.container {
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
}

.adicionar {
  text-align: end;
}

.adicionar span {
  cursor: pointer;
}

.adicionar span:hover {
  color: rgb(204, 204, 204);
}

.container h2 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 1em;
}

.container ul {
  margin-top: 1em
}

.container li {
  background-color: rgb(255, 255, 255);
  display: flex;
  width: 100%;
  padding: 1em;
  border: 1px solid gray;
  border-radius: 7px;
  margin-bottom: 0.3em;
  justify-content: space-between;
  box-sizing: border-box;
}

.container li:hover {
  background-color: rgb(245, 245, 245);
}

.container li span {
  cursor: default
}

.container li .botoes {
  cursor: pointer;
}