.sem_dados {
  background-color: #cae2ff;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sem_dados img {
  width: 70px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}