.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 10;
}

.modal:focus {
  outline: none;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5)
}

.overlay:focus {
  outline: none;
}

.fechar {
  position: absolute;
  font-size: 50px;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
  color: rgb(255, 255, 255);
}

.modalConteudo {
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.modalConteudo img {
  max-width: 80vw;
  max-height: 100vh;
  object-fit: contain;
}
