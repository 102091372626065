.container {
  z-index: 1;
  position: absolute;
  bottom: 50px
}

.cartao {
  background-color: white;
  border-radius: 5px;
  width: 450px;
  height: 230px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 1em 0.5em;
}

.listaTemplates {
  flex: 1;
  overflow-y: auto;
  border-right: 1px solid #0003;
}

.listaTemplates li {
  text-align: center;
  padding: 1em 1em;
  cursor: pointer;
  border-bottom: 1px solid #0003;
}

.listaTemplates li:hover {
  background-color: #e3e7eb;
}

.listaTemplates li:active {
  background-color: #e3e7eb;
}

.listaTemplates::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.listaTemplates::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.mensagemTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: 1;
}

.mensagemTemplate__body {
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 180px;
  overflow-y: auto;
  padding: 1em;
}

.mensagemTemplate__body::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.mensagemTemplate__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.mensagemTemplate__rodape {
  display: flex;
  font-size: 12px;
  flex: 1;
  font-style: italic;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
}