.container {
  flex: 1;
  overflow-y: auto;
  background-image: url('./i-Stroke.svg');
  background-color: #ececec;
  padding: .5em 0;
}

.container::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}