.cabecalho .titulo {
  display: flex;
  margin-bottom: 1em;
}

.cabecalho .titulo h2 {
  display: flex;
  font-size: 25px;
  font-weight: 600;
  margin-right: 1em;
  align-items: center;
  justify-content: center;
}

.filtro {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 1em;
  padding: 1em;
}

.corpo {
  display: flex;
  flex-direction: column;
}

.corpo .itens {
  display: flex;
  margin: 1em;
  justify-content: space-evenly
}