.container {
  display: flex;
}

.container.remetente {
  justify-content: flex-end;
}

.linha {
  margin: 3px 10px 3px 10px;
  display: flex;
  border-radius: 10px 10px 10px 0px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.13);
  flex-direction: column;
  padding: 3px;
  max-width: 60%;
  background-color: #FFFFFF;
  border: 1px solid #BDC0C1;
}

.linha.remetente {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.13);
  border-radius: 10px 10px 0px 10px;
  background-color: #DFFFC6;
}

.texto {
  overflow-wrap: break-word;
  font-size: 14px;
  margin: 5px 40px 10px 10px;
}

.rodape {
  display: flex;
  font-size: 11px;
  color: #999;
  justify-content: right;
  height: 15px;
  margin: -5px 5px 0;
}

.check {
  margin: 0 .5em;
}

.check.read {
  color: blue;
}

.conteudo__corpo {
  display: flex;
  overflow: hidden;
}

.area_documento {
  font-size: 50px;
}

.area_informativo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.area_informativo span {
  margin-right: 10px;
}

.download {
  font-size: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.sem_documento {
  margin-bottom: 1em;
}