.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid var(--cor-cinza);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 8px;
  outline: none;
  box-shadow: 1px 1px 2px 1px #0003;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 1000px;
}

.cabecalho {
  display: flex;
  justify-content: space-between;
}

.voltar {
  cursor: pointer;
}

.cabecalho span {
  font-size: 18px;
  font-weight: 600;
}

.conteudo {
  display: flex;
  padding: 1em;
  height: 450px;
}

.informacoes {
  padding: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.informacoes table {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  font-size: 14px;
  gap: .2em;
}

.informacoes td:first-child {
  width: 120px;
  font-weight: 600;
  padding: .3em;
}

.gerar {
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.conversa {
  width: 900px;
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(216, 216, 216);
  overflow-y: auto;
}

.transferir {
  display: flex;
  margin-bottom: 1em;
}