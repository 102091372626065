.container {
  background: rgb(250, 182, 25);
  background: var(--cor-primaria-gradiente-vertical);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

.cabecalho {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
}

.logo {
  height: 35px;
  display: flex;
  align-items: center;
}

.logo img {
  height: 35px;
}

.logo p {
  font-size: 20px;
  color: gray;
  font-weight: 600;
  margin: 0 10px 0 2px;
}

.links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  margin-right: 1em;
}

.cabecalho span {
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
}

.pipe {
  border-left: 1px solid white;
  height: 30px;
}

.usuario {
  display: flex;
  position: relative;
}

.avatar {
  font-size: 35px;
  color: white;
  display: inline;
  cursor: pointer;
  border-radius: 50%;
}

.online {
  width: 10px;
  height: 10px;
  background-color: #00be00;
  border: 1px solid rgb(78, 78, 78);
  border-radius: 100px;
  position: absolute;
  top: 2px;
  right: 0;
}

.offline {
  width: 10px;
  height: 10px;
  background-color: gray;
  border: 1px solid rgb(78, 78, 78);
  border-radius: 100px;
  position: absolute;
  top: 2px;
  right: 0;
}

.lado_esquerdo {
  display: flex;
  gap: 0.5em;
}

.lado_esquerdo .logo {
  background-color: white;
  padding: 0.5em 1em;
  border-radius: 0 25px 0 0;
}

.lado_esquerdo select {
  border: none;
  color: white;
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
}

.lado_esquerdo option {
  color: black;
}
