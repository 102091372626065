.container {
  display: flex;
  flex-direction: column;
  height: 85px;
  background-color: var(--cor-cinza-escuro);
  border-left: 1px solid rgb(216, 216, 216);
  padding: 16px 5px 0 5px;
  margin-bottom: 5px;
  gap: .5em;
}

.titulo h2 {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  color: white;
}

.opcoes {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: space-around;
  color: white;
}

.item {
  width: 100%;
}