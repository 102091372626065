.label {
  padding: 0.5em 0;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;

  padding: 0.5em;
  appearance: none;
  background: #fff;
  background-size: 10px;
  transition: border-color .1s ease-in-out, box-shadow .1s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.limitaLargura {
  width: 200px;
}