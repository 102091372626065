.card {
  padding: 0 3px;
}

.card h3 {
  border-radius: 8px 8px 0 0;
  padding: .5em;
  color: white;
  background: var(--cor-primaria-escura);
}

.ocorrencias {
  display: flex;
  flex-direction: column;
  gap: .5em;
  padding: .5em 0;
}

.ocorrencias button {
  width: 100px;
}

.ocorrencias textarea {
  padding: .5em;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #C0C0C0;
  font-family: var(--font-poppins);
  resize: none;
}

.corpo {
  display: flex;
  width: 300px;
}

.corpo>* {
  flex: 1;
  width: 50%;
}
