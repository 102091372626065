.container {
  padding: 1em;
  height: calc(100vh - 210px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.cabecalho {
  margin-bottom: 1em;
}

.protocolos {
  display: flex;
  flex-direction: column;
}

.card {
  cursor: pointer;
  padding: .7em;
  border-bottom: 1px solid #c0c0c0;
}

.card span {
  font-size: 14px;
}

.card:hover {
  background-color: rgb(241, 241, 241);
}

.card:active {
  background-color: rgb(207, 207, 207);
}

.card .rodape {
  margin-top: .5em;
  font-size: 11px;
  font-weight: 300;
  display: flex;
  justify-content: space-between
}
