.card {
  margin-bottom: 1em;
}

.lista {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  gap: .5em;
}

.endereco {
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.lista li {
  font-size: 13px;
  font-weight: 300;
}

.lista span {
  font-weight: 500;
}

.pipe {
  border-top: 1px solid #C0C0C0;
  margin: 8px 0;
}
