.container {
  height: 60px;
  background-color: #D3D5D3;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #A7AAAA;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
}

.nome_cliente {
  color: #686B6D;
  font-weight: 600;
}

.botoes {
  display: flex;
  gap: .5em;
}
