.container {
  display: flex;
  flex-direction: column;
  padding: 1em
}

.titulo {
  font-size: 16px;
  font-weight: 600;
}

.conteudo {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.upload {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 1em;
}

.upload label {
  display: flex;
  background-color: #247DC3;
  padding: 1em;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 1em;
  cursor: pointer;
}

.upload label span {
  margin-right: 1em;
}

.upload p {
  font-size: 12px;
  font-style: italic;
}

.conteudo button {
  width: 100px;
}