.card {
  padding: .5em;
}

.lista {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  gap: .5em;
}

.buscar {
  display: flex;
  gap: .5em
}


.lista li {
  font-size: 13px;
  font-weight: 300;
}

.lista p {
  margin-top: .5em;
}

.lista span {
  font-weight: 500;
}

.pipe {
  border-top: 1px solid #C0C0C0;
  margin: 16px 0;
}