.container {
  display: flex;
  flex: 1;
  padding: 1em;
  cursor: pointer;
}

.ativo {
  color: black;
  font-weight: 600;
  border: 1px solid white;
  border-radius: 10px 10px 0 0;
  background-color: white;
}

.container span {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;

}