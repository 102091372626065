.card {
  display: flex;
  background-color: white;
  border: 1px solid #C0C0C0;
  border-radius: 6px;
  margin-bottom: .5em;
  cursor: pointer;
}

.card:hover {
  background-color: rgb(238, 238, 238);
}

.card:active {
  background-color: rgb(230, 230, 230);
}

.conteudo_protocolos {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.8em 0.2em;
}


.conteudo_protocolos thead,
.conteudo_protocolos tbody {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}

.conteudo_protocolos th {
  font-weight: 600;
  padding-bottom: 0.5em;
}

.conteudo_protocolos th,
.conteudo_protocolos td {
  width: 150px;
  text-align: center;
}