.container {
  display: flex;
}

.container.remetente {
  justify-content: flex-end;
}

.linha {
  margin: 3px 10px 3px 10px;
  display: flex;
  border-radius: 10px 10px 10px 0px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.13);
  flex-direction: column;
  padding: 3px;
  max-width: 60%;
  background-color: #FFFFFF;
  border: 1px solid #BDC0C1;
}

.linha.remetente {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.13);
  border-radius: 10px 10px 0px 10px;
  background-color: #DFFFC6;
}

.conteudo {
  display: flex;
  flex-direction: column;
  padding: .5em;
  max-height: 300px;
  max-width: 300px;
}

.corpo {
  display: flex;
  overflow: hidden;
  cursor: pointer;
}

.corpo img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

.imagem {
  position: relative;
  display: flex;
  flex: 1;
}

.icone {
  display: flex;
  position: absolute;
  padding: .5em;
  top: 3px;
  right: 3px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 15px;
  width: 10px;
  height: 10px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.imagem:hover .icone {
  opacity: 1;
}

.rodape {
  display: flex;
  font-size: 11px;
  color: #999;
  justify-content: right;
  height: 15px;
  margin: 10px 5px -5px 0;
}

.check {
  margin: 0 .5em;
}

.check.read {
  color: blue;
}

.sem_imagem {
  display: flex;
}