.container {
  z-index: 1;
  position: absolute;
  bottom: 50px
}

.cartao {
  background-color: white;
  border-radius: 5px;
  max-width: 350px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 1em 0.5em;
}

.tipos {
  width: 150px;
}

.item {
  margin-bottom: 1em;
  cursor: pointer;
}

.item:hover {
  color: gray;
}

.item span {
  margin-left: .5em;
}

.arquivo {
  border-left: 1px solid gray;
  width: 200px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.arquivo span {
  margin-bottom: 1em;
}