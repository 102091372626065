.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 210px);
  overflow-y: auto;
  position: relative;
}

.cabecalho {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cabecalho {
  font-size: 14px;
}

.data {
  margin-top: .2em;
  font-weight: 300;
  font-size: 12px;
}

.principal {
  display: flex;
  justify-content: space-between;
}

.informacoes {
  padding: 1em 0 0 0
}

.informacoes span {
  color: blue;
  cursor: pointer;
}

.informacoes span:hover {
  color: darkblue;
}

.voltar {
  cursor: pointer;
}

.conversa {
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(216, 216, 216);
  overflow-y: auto;
  /* Adicione overflow-y: auto apenas ao elemento .conversa para exibir a barra de rolagem dentro dele */
}

.conversa::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.conversa::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
