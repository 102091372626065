.container {
  width: 110px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  background: white;
}

.header {
  color: white;
  font-size: 14px;
  border-bottom: 1px solid c0c0c0;
  padding: 0.5em;
  width: -webkit-fill-available;
  border-radius: 6px 6px 0 0;
}

.header.laranja {
  background: rgba(250, 182, 25, 1) 0%
}

.header.verde {
  background: rgb(1, 187, 11) 0%
}

.header.vermelho {
  background: rgb(182, 20, 20) 0%
}

.header.amarelo {
  background: rgb(219, 223, 6) 0%
}

.header.azul_escuro {
  background: rgb(1, 16, 83) 150%
}

.header.azul_claro {
  background: rgb(25, 179, 250) 0%
}

.body {
  display: flex;
  padding: 1em;
  text-align: center;
  text-align: center;
  flex: 1;
  align-items: center;
  justify-content: center;
}