.container {
  background: #ECEDEC;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: .5em;
}

.container:hover {
  background-color: #e3e7eb;
}

.selecionada {
  background: var(--cor-primaria-gradiente);
  color: white;
}

.avatar {
  font-size: 35px;
  color: #686B6D;
  padding: 0 .2em;
}

.selecionada .avatar {
  color: white;
}

.nome {
  font-size: 14px;
  padding: 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conteudo {

  display: flex;
  align-items: center;
}

.notificacao {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.alerta {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-primaria);
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: .4em;
  font-size: 11px;
  font-weight: 600;
  color: white
}
