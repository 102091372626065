.container {
  width: 350px;
  background-color: rgb(252, 252, 252);
  margin-right: 1em;
}

.opcoes {
  padding: 0.5em;
  border: 1px solid rgb(226, 226, 226);
}

.opcoes li {
  cursor: pointer;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 5px;
  padding: 1em;
  margin-bottom: 2px;
}


.opcoes li:hover {
  background-color: rgb(230, 230, 230);
}

.opcoes li:active {
  background-color: rgb(220, 220, 220);
}

.opcaoSelecionada {
  background-color: rgb(240, 240, 240);
}
