.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  border: 1px solid var(--cor-cinza);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 8px;
  outline: none;
  z-index: 2;
  box-shadow: 1px 1px 2px 1px #0003;
}

.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.cabecalho {
  display: flex;
  justify-content: space-between;
}

.voltar {
  cursor: pointer;
}

.cabecalho span {
  font-size: 18px;
  font-weight: 600;
}

.corpo {
  padding: 1em;
}

.corpo .titulo {
  font-weight: 600;
}

.corpo td {
  padding: .2em .3em;
}
