.container {
  height: 115px;
  bottom: 0;
  padding: 10px;
  background-color: #D3D5D3;
  border: 1px solid #A7AAAA;
  display: flex;
  flex-direction: column;
}

.texto {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px
}

.enviar {
  font-size: 30px;
  cursor: pointer;
  color: #686B6D;
}

.texto textarea {
  padding: 10px 10px 10px 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 53px;
  box-shadow: inset 0 0 1px 1px #0003;
  resize: none;
  font-family: var(--font-poppins)
}

textarea:focus {
  outline: none;
}

.rodape {
  display: flex;
  padding: 1em 0;
  justify-content: space-between;
}

.funcoes * {
  color: #686B6D;
  font-size: 20px;
  padding: 0 6px;
  cursor: pointer;
}
