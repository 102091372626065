.container {
  background: white;
  padding: 1em;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 5px;
}

.container td,
.container th,
.container table,
.container tr {
  display: flex;
}

.container table {
  flex-direction: column;
}

.container tr {
  flex: 1;
  justify-content: space-around;
}

.container tr:hover {
  background-color: rgb(183, 224, 255);
  cursor: pointer;
}

.container th {
  font-weight: 600;
  background-color: rgb(216, 238, 255);
  user-select: none;
}

.container th,
.container td {
  padding: .5em;
  font-size: 13px;
}

.atendente {
  flex: 1
}

.atendimento {
  width: 120px;
}

.finalizado {
  width: 75px;
}

.resposta {
  width: 160px;
}

.tempoAtendimento {
  width: 200px;
}

.tabela_conteudo .resposta,
.tabela_conteudo .tempoAtendimento,
.tabela_conteudo .atendimento,
.tabela_conteudo .finalizado {
  text-align: center;
  justify-content: center;
}
