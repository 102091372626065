.container {
  display: flex;
  flex-direction: column;
  background: rgb(250, 250, 250);
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 8px;
}

.cabecalho {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.container h2 {
  font-size: 25px;
  font-weight: 600;
}

.update {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 10px;
  padding: .5em;
  background: rgb(255, 255, 255);
  cursor: pointer;
}

.conteudo {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.modulos {
  display: flex;
  justify-content: space-around;
  user-select: none;
}

.modulos span {
  padding: .5em;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.ativo {
  border: 1px solid rgb(223, 223, 223);
  border-bottom: 0;
  background: white;
  margin-bottom: -1px;
}