.loading{
    width: 100%;
    text-align: center;
    justify-content: center;
    align-content: center;
}

.loader{
    color: var(--cor-azul-claro);
    margin: 1em;
    width: 30px;
    height: 30px;
    animation: loading 0.75s ease infinite
}

@keyframes loading{
    from{ transform: rotate(0turn);}
    to { transform: rotate(1turn);}
}