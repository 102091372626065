.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  border: 1px solid var(--cor-cinza);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 8px;
  outline: none;
  z-index: 2;
  box-shadow: 1px 1px 2px 1px #0003;
}

.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.formulario {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.header h2 {
  color: var(--cor-primaria);
  font-size: 18px;
  padding: 2em;
  background-color: #f5f5fa;
}

.body {
  display: flex;
  padding: 2em;
  flex-wrap: wrap;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: space-around;
  padding: 0em 2em 2em 2em;
}