.cards {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
}

.card {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 10px;
  padding: 1em;
  background: rgb(255, 255, 255);
}

.card_cabecalho {
  color: black;
  font-size: 18px;
  user-select: none;
}

.card_corpo {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 0.5em;
  user-select: none;
}

.card_corpo p {
  padding-top: 1em;
  color: black;
  width: 140px;
}