.container{
    background: var(--cor-primaria);
    background: linear-gradient(90deg, rgba(250,182,25,1) 0%, rgba(236,102,28,1) 100%);
    height: 100vh;
}

.loading{
    display: flex;
    width: 100%;
    height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
}