.link {
  color: white;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.link:hover {
  color: rgb(238, 238, 238);
  text-decoration: underline;
}