.card {
  padding: 0 .5em;
}

.card h3 {
  border-radius: 8px 8px 0 0;
  padding: .5em;
  color: white;
  background: var(--cor-primaria-escura);
}

.conteudo {
  display: flex;
  flex-direction: column;
  gap: .8em;
  padding: 1em .5em;
}

.evidencias {
  display: flex;
  flex-wrap: wrap;
  gap: .5em;
  justify-content: center;
}

.evidencia {
  display: flex;
  position: relative;
}

.evidencia img {
  width: 80px;
  height: auto;
  max-height: 80px;
  object-fit: cover;
}

.evidencia .icone {
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: black;
  background-color: #fff;
  border-radius: 10px;
}