.container {
  display: flex;
  box-sizing: border-box;
  margin: auto;
  max-width: 1400px;
  margin-top: 1em;
}

.conteudo {
  width: 100%;
}
