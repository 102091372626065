.container {
  height: calc(100vh - 190px);
  overflow-y: scroll;
  padding: 1em 2px;
}

.container::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}