.container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: .5em;
}

.container::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
